.container {
  &.container-input {
    padding: 1px 2px 1px 2px;
  }
  &.container-text-upper {
    white-space: pre-line !important;
    padding: 1px 2px 0px 2px;
  }
  &.container-text-lower {
    white-space: pre-line !important;
    padding: 0px 2px 1px 2px;
  }
  &.container-banner {
    border-radius: 3px;
    width: 100%;
  }
}

