.chip {

    overflow: inherit;
    background-color: $primary;
    color: white;
    font-size: 12px;
    border-radius: 2px;
    text-align: center;
    
    .icon
    {
      color: white;
      cursor: pointer;
    }

    &.table
    {
      font-size: 11px;
    }

    &.text-wrap {
      white-space: pre-line !important;
    }

    &.secondary { background-color: $secondary; }
    &.green {  background-color: $green; }
    &.red { background-color: $red; }
    &.black {  background-color: $black; }
    &.blue {  background-color: $blue; }
    &.royalBlue {  background-color: $royalBlue; }
    &.orange { background-color: $orange; }
    &.yellow { background-color: $yellow; }
    &.pink { background-color: $pink; }
    &.purple {  background-color: $purple; }
    &.grey { background-color: $grey;  }
    &.lightGrey { background-color: $lightGrey; }
    &.cyan { background-color: $cyan; }
    &.magenta { background-color: $magenta; }
    &.waste { background-color: $waste; }
    &.batched { background-color: $batched; }

    &.text-secondary { color: $secondary; }
    &.text-green {  color: $green; }
    &.text-red { color: $red; }
    &.text-black {  color: $black; }
    &.text-blue {  color: $blue; }
    &.text-royalBlue {  color: $royalBlue; }
    &.text-orange { color: $orange; }
    &.text-yellow { color: $yellow; }
    &.text-pink { color: $pink; }
    &.text-purple {  color: $purple; }
    &.text-grey { color: $grey;  }
    &.text-lightGrey { color: $lightGrey; }
    &.text-cyan { color: $cyan; }
    &.text-magenta { color: $magenta; }
    &.text-waste { color: $waste; }
    &.text-batched { background-color: $batched; }

    &.font-10 { font-size: 10px; }
    &.border-radius-5 { border-radius: 5px; }
    &.padding-5 { padding: 2px 5px;  }
}