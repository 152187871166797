.calendar {
    .clickable {
        cursor: pointer;
    }

    .dayContainer {
        padding: 0;
        width: calc(100% / 7);

        @media screen and (max-width: $break-medium) {
            width: 100%;
        }

        .day {
            position: relative;
            height: 150px;
            border-bottom: 2px solid $grey;
            border-right: 2px solid $grey;
            @include transition(background-color 0.2s ease);
            padding-bottom: 24px;

            @media screen and (max-width: $break-medium) {
                border: 1px solid $grey !important;
            }


            &.borTop {
                border-top: 2px solid $grey;
            }

            &.borLeft {
                border-left: 2px solid $grey;
            }

            &.noBottom {
                border-bottom: none;
            }

            &.first {
                margin-left: -1px;

                @media screen and (max-width: $break-medium) {
                    margin-left: 0;
                }
            }

            &.weekend {
                background-color: lighten($grey, 25%)
            }


            &.today {
                background-color: lighten($grey, 20%)
            }

            .dayInfo {
                position: absolute;
                top: 0;
                right: 5px;
                font-size: 12px;
                color: grey;
            }

            .eventContainer {
                height: calc(180px - 44px);
                overflow-y: auto;
                overflow-x: hidden;

                .event {
                    width: 100%;
                    max-width: 100%;
                    color: white;
                    background-color: $grey;
                    padding: 0 5px;
                    cursor: pointer;
                    margin-bottom: 2px;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    hyphens: auto;
                    display: flex;
                    position: relative;
                    @include transition(background-color 0.3s ease);

                    .icon {
                        margin: auto 0;
                    }

                    .name {
                        flex-grow: 1;
                        font-size: 13px;
                    }

                    &.primary {
                        background-color: $primary;
                        color: white;
                    }

                    &.secondary {
                        background-color: $secondary;
                        color: white;
                    }

                    &.green {
                        background-color: $green;
                        color: white !important;
                    }

                    &.red {
                        background-color: $red;
                        color: white;
                    }

                    &.yellow {
                        background-color: $yellow;
                        color: black;
                    }

                    &.purple {
                        background-color: $purple;
                        color: white;
                    }

                    &.black {
                        background-color: black;
                        color: white;
                    }

                    &.highlight {
                        &.primary {
                            background-color: lighten($primary, 10%);
                        }

                        &.secondary {
                            background-color: lighten($secondary, 10%);
                        }

                        &.red {
                            background-color: lighten($red, 10%);
                        }

                        &.yellow {
                            background-color: lighten($yellow, 10%);
                        }

                        &.purple {
                            background-color: lighten($purple, 10%);
                        }

                        &.black {
                            background-color: lighten(black, 10%);
                        }
                    }
                }
            }

            .addEvent {
                position: absolute;
                bottom: 0;
                width: 100%;
                cursor: pointer;
                text-align: center;
                @include transition(color 0.3s ease);

                .icon-plus {
                    @include transition(font-weight 0.3s ease);
                }

                &:hover {
                    color: $primary;

                    .icon-plus {
                        font-weight: bold;
                    }
                }
            }
        }

    }
}