.sopHub {
  &.container {
    width: 100%;
  }
  
  &.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  &.column {
    min-width: 0;
  }
  
  &.columns {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &.sopDrawer-options-innerControl {
    width: 70%;
    @media screen and (max-width: 400px) {
      &.sopDrawer-options-innerControl {
        overflow: hidden;
        width: 100%;
      }
    }
  }

  &.sopDrawer-options-innerButton {
    width: 30%;
    @media screen and (max-width: 400px) {
      &.sopDrawer-options-innerButton {
        overflow: hidden;
        width: 100%;
      }
    }
  }

  &.sopDrawer-options-left {
    width: 30%;
    @media screen and (max-width: 400px) {
      &.sopDrawer-options-left {
        overflow: hidden;
        width: 100%;
      }
    }
  }

  &.sopDrawer-options-right {
    width: 70%;
    @media screen and (max-width: 400px) {
      &.sopDrawer-options-right {
        overflow: hidden;
        width: 100%;
      }
    }
  }

  &.sopDrawer-options-po {
    flex: 1 1 0%;
    @media screen and (max-width: 400px) {
      &.sopDrawer-options-po {
        overflow: hidden;
        flex: auto;
        width: 100%;
      }
    }
  }

  &.sopDrawer-sync-right {
    width: 20%;
  }
}

