.alert {
    position: fixed;
    opacity: 0;
    @include transition(opacity 0.3s ease);
    background-color: $grey;
    z-index: 999999;
    bottom: 20px;
    right: 20px;
    color: white;
    font-size: 12px;
    padding: 10px 18px;
    border-radius: 8px;

    &.open {
        opacity: 1;
    }
}