.productConfigurator
{
    .productListView
    {
        .product
        {
            text-align: center;
            box-shadow: 0px 0px 2px #CCCCCC;
            padding: 20px;

            &:hover{
                box-shadow: 0px 0px 10px #CCCCCC;
                cursor: pointer;
            }

            .imagePreview
            {
                width: 100%;
                height: 150px;
                background-size: cover;
                background-position: center;
                margin-bottom: 15px;
            }

            .title
            {
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 10px;
            }
            .description
            {
                font-size: 12px;
                height: auto;
                max-height: 150px;
                overflow: auto;
                margin-bottom: 25px;
            }
        }
    }
}

.productConfiguratorSelected
{
    .leftCol
    {
        padding: 20px;
        box-shadow: 0px 0px 5px #CCCCCC;
    }
    .rightCol
    {
        width: 100%;
        padding: 20px;
        box-shadow: 0px 0px 5px #CCCCCC;
    }

    .compatiblePart
    {
        border: 1px solid #EEEEEE;
        padding: 15px;
        margin: 10px;
        cursor: pointer;

        &:hover
        {
            box-shadow: 0px 0px 8px #DDDDDD;
        }
    }
}