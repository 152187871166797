
.errored {

    &.message {
        margin-top: 1px !important;
        font-size: 11px !important;
        color: rgba(217, 30, 24, 1) !important;
        text-align: left !important;
    }

    &.border {
        border-color: rgba(217, 30, 24, 0.8) !important;
        border-radius: 2px !important;
        border-width: 1px !important;
        transition: border-color 2s;
    }
    
    &.button {
        background-color: $green !important;
        color: white !important;
        border-radius: 5px !important;
        cursor: pointer !important;
        display: inline-block !important;
        
        &.outline {
            border-color: rgba(217, 30, 24, 0.9) !important;
            box-shadow: 0 0 4px rgba(217, 30, 24, 1) !important;
            border-radius: 6px !important;
            border-width: 1px !important;
            transition: border-color 2s !important;
        }

        .text-red {
          color: red;
        }
    }

    &.div {
      border: 1px solid rgba(217, 30, 24, 0.9) !important;
      box-shadow: 0 0 4px rgba(217, 30, 24, 1) !important;
    }

}

