.tableContainer {
  background-color: $white;
}

.tableCell {
  padding: 1px;
}

.hide {
  display: none;
}

.mainTable {

    font-size: 12px;
    margin-bottom: 5px;

    th {
      padding-left: 1px;
      padding-right: 1px;
    }
    td {
      border-bottom: 1px solid $lightGrey;
      background-color: rgba(#EFEFEF, 0.2);
      height: 0;
      width: 0;
    }

    @-moz-document url-prefix() {
      th {
        padding-left: 1px;
        padding-right: 1px;
      }
      td {
        background-color: rgba(#EFEFEF, 0.2);
        width: 0;
        height: inherit;
      }
    }

    tr {
      transition: 0.1s;
    }

    .row-hover:hover {
      background-color: $lightGrey;
    }

    thead {
        background-color: white;
        white-space: nowrap;
        padding: 6px 8px 6px 8px;
    }

    th {
        font-size: 10px;
        text-align: left;
        position: relative;
        background-color: rgba(#EFEFEF, 0.6);
        border-top: 1px solid $lightGrey;
        user-select: none;
        vertical-align: top;
    }

    .sortLabel {
      
      display: inline;
      float: right;

      .up-arrow {
          width: 0;
          height: 0;
          margin: 4;
          border: solid 4px transparent;
          background: transparent;
          border-bottom: solid 6px grey;
          border-top-width: 0;
          cursor: pointer;

          &.hide {
            display: none;
          }
      }
      
      .down-arrow {
          width: 0;
          height: 0;
          margin: 4;
          border: solid 4px transparent;
          background: transparent;
          border-top: solid 6px grey;
          border-bottom-width: 0;
          margin-top:1px;
          cursor: pointer;

          &.hide {
            display: none;
          }
      }
    }

    .expand-rows {

      &.up-arrow {
          width: 0;
          height: 0;
          margin: 7px 0px 0px 4px;
          border: solid 4px transparent;
          background: transparent;
          border-bottom: solid 6px grey;
          border-top-width: 0;
          cursor: pointer;

          &.hide {
            display: none;
          }
      }
    }

    .filterIcon {
        display: inline;
        font-size: 12px;

        &.active {
            span {
                color: $green;
            }
        }
    }

    &.small {
      th {
          font-size: 10px;
      }
      td {
          font-size: 11px;
      }
    }   

    &.cellBorders {
      border-bottom: 1px solid $lightGrey;
    }

    &.headerBorders {
      border-bottom: 1px solid rgba($grey, 0.4);
      padding: 2px 0px 2px 0px;
    }

    &.searchBorders {
      padding: 1px;
    }
}

.subTable {
  width: 100%;
  font-size: 12px;
  border-left: 1px solid $lightGrey;
  border-top: 1px solid $lightGrey;

  th,
  td {
      border-right: 1px solid $lightGrey;
      border-bottom: 1px solid $lightGrey;
      padding: 6px 8px 6px 8px;
      height: 0;
  }

  thead {
      background-color: white;
  }

  th {
      font-size: 10px;
      text-align: left;
      position: relative;
      background-color: white;
      border-top: 1px solid $lightGrey;
      user-select: none;
      vertical-align: top;
  }

  .sortLabel {
      display: inline;
      float: right;
  }

  .filterIcon {
      display: inline;
      font-size: 12px;

      &.active {
          span {
              color: $green;
          }
      }
  }

  &.small {

      th,
      td {
          padding: 2px 3px;
      }

      td {
          font-size: 12px;
      }
  }
}

.columnFilterHolder {
    position: absolute;
    z-index: 99;
}

.filtersHolder {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;

    .clear,
    .expand,
    .filter {
        padding: 5px 8px;
        margin-right: 5px;
        background-color: $primary;
        color: white;
        border-radius: 10px;
        font-size: 12px;
        display: flex;

        .clear {
            span {
                color: white;
            }

            cursor: pointer;
            margin-left: 5px;

        }
    }
}


.optionsContainer {
  margin: 0px 0px 5px 0px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background-color: $lightGrey;

  .selected {
    background-color: lightGrey;
  }

  .clear,
  .expand,
  .filter,
  .refresh,
  .export {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 4px 2px 4px 2px;
    min-width: 28px;
    height: 28px;
    border: 1px solid $grey;
    border-radius: 3px;
    cursor: pointer;

    &.clickable:hover {
      background-color: lightGrey;
      transition: 0.3s;
    }
  }

  .recordSelect {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    flex-direction: row;
    flex-grow: 4;
    margin: 4px 2px 4px 2px;
    padding: 2px 0px 0px 5px;

    @media screen and (max-width: 980px) {
      &.recordSelect {
        display: none;
      }
    }
  }

  .pageSelect {
    padding: 0px 10px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: row;
    text-align: center;
    
    
    .pageForward,
    .pageFirst,
    .pageLast,
    .pageBack {
        margin: 4px 1px 4px 1px;
        width: 28px;
        height: 28px;
        border: 1px solid $grey;
        border-radius: 3px;
        cursor: pointer;
    }

    .pageLast,
    .pageFirst {
        .second {
            margin-left: -7px;
        }
    }

    .jumpToPage {
      margin-right: 5px;
      margin: 4px 1px 4px 1px;
      height: 28px;
      min-width: 30px;
    } 

    .jumpToPageInner {
      height: 28px;
      background-color: #efefef;
      border: 1px solid $grey;
      border-radius: 3px;
    } 

    .clickable:hover {
      background-color: lightGrey;
      transition: 0.3s;
    }

    .disabled {
        cursor: not-allowed;
        color: grey;
    }


    .pages {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        text-align: center;

        .page {
            display: flex;
            justify-content: center;
            min-width: 28px;
            max-width: 40px;
            height: 28px;
            margin: 4px 1px 4px 1px;
            border: 1px solid $grey;
            border-radius: 3px;
            cursor: pointer;

            .number {
              justify-content: center;
              overflow: hidden;
              text-overflow: ellipsis;
              margin: 4px 1px 4px 1px;
              width: 100%;
            }

            &.false {
              color: grey;
            }
            &.selected {
              color: green;
              font-weight: bold;
            }
        }
    }

    @media screen and (max-width: 600px) {
      &.pagination {
        justify-content: flex-start;
      }
    }
  }

  .options {
    flex-grow: 1;
    justify-content: flex-start;
  }
}

.panel-expand {

  transition: all 200ms ease-in-out;
  display: grid;
  grid-template-rows: 0fr;

  &.open {
    grid-template-rows: 1fr;
  }

  &.close {
    grid-template-rows: 0fr;
  }
  
  &.content {
    overflow: hidden;
  }

}


