.loginOuter
{
    height: 106.3vh;
    margin: 0px;
    padding: 0px;
    .loginLeft
    {
        padding: 20px;
        background-color: $primary;
        color: white;
        background-image: $blueAgiloBg;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column; 
        justify-content: center;
        box-shadow: 0px 0px 10px #777777;
        z-index: 1;
    }
    .loginRight
    {
        transition: background-image 1s ease-in-out;
        background-color: white;
    }
}
