.avatar
{
    width: 23px;
    height: 23px;
    border-radius: 100px;
    background-color: green;
    text-align: center;
    color: white;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}