/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    height: 18px;
    cursor: pointer;

    input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked+.slider {
            background-color: $primary;
        }

        &:checked+.slider:before {
            transform: translateX(30px);
        }
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        width: 50px;

        &:before {
            position: absolute;
            content: "";
            height: 10px;
            width: 10px;
            left: 5px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
        }

        &.round {
            border-radius: 16px;
        }

        &.round:before {
            border-radius: 50%;
        }
    }

    .label {
        font-size: 14px;
        padding: 0 0 0 0;
        margin-left: 60px;
        display: inline-block;
        font-weight: normal;
    }
}