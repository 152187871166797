.salesHub {
  &.submitDeal-options {
    flex: 1 1 0%;
    @media screen and (max-width: 1300px) {
      &.submitDeal-options {
        overflow: hidden;
        flex: auto;
        width: 100%;
      }
    }
  }
  &.submitDeal-modalWidth {
    width: 30%;
    @media screen and (max-width: 1300px) {
      &.submitDeal-modalWidth {
        width: 60%;
      }
    }
  }
  &.submitDeal-button {
    flex-direction: row;
    @media screen and (max-width: 600px) {
      &.submitDeal-button {
        flex-direction: column;
        margin-left: 30px;
      }
    }
  }
}

