.footerContainer {
  background-color: lightgray;
  font-size: 14px;
}

.footerChild {
  @media screen and (max-width: 1200px) {
    justify-content: center;
    width: 100%;
  }
}

