$default: rgba(#000033, 1);
$primary: rgba(#000033, 1);
$secondary: rgba(#708F9E, 1);
$background: rgba(#EFEFEF, 1);
$white: rgba(#FFFFFF, 1);
$black: rgba(#000000, 1);
$green: rgba(#00B200, 1);
$darkGreen: rgba(#336666, 0.8);
$limeGreen: rgba(#c5ea8c, 0.2);
$pineGreen:rgba(#288654, 0.9);
$red: rgba(#FF0000, 1);
$blue: rgba(#00B8FF, 1);
$blue-grey: rgba(#656e77, 1);
$royalBlue: rgba(#002c80, 1);  
$lightBlue: rgba(#CCE6FF, 1);
$fordBlue: rgba(#2c3968, 1);
$azureBlue:rgba(#5072A7, 1);
$orange: rgba(#FF9100, 1);
$lightOrange: rgba(#FFE4CA, 1);
$novemberOrange: rgba(	#dc7500, 1); 
$yellow: rgba(#F0E68C, 1);  
$pink: rgba(#FFB6C1, 1); 
$darkPink: rgba(#CC6699, 1); 
$lightGrey: rgba(#EFEFEF, 1);
$silver: rgba(#DCDCDC, 1);
$mediumGrey: rgba(grey, 0.4);
$purple: rgba(purple, 0.2);
$darkPurple: rgba(#5F1564, 1);
$grey: rgba(grey, 1);
$cyan: rgba(#A6E4FF, 1);
$magenta: rgba(#FFA6D5, 1);
$waste: rgba(#00CC66, 1);
$batched : rgba(#89AE51, 1);
$blueAgiloBg: url('../assetts/backgrounds/blue.png');
$gradingSilver: rgba(#C0C0C0, 1);
$gradingDiamond: rgba(#B9F2FF, 1);
$gradingBronze: rgba(#CD7F32, 1);
$gradingGold: rgba(#FFD700, 1);
$gradingPlatinum: rgba(#E5E4E2, 1);

.reactIcons {
  &.primary { fill: $primary !important; }
  &.secondary {  fill: $secondary !important; }
  &.pineGreen { fill: $pineGreen !important; }
  &.azureBlue { fill: $azureBlue !important; }
  &.green { fill: $green !important; }
  &.darkGreen { fill: $darkGreen !important; }
  &.limeGreen { fill: $limeGreen !important; }
  &.red { fill: $red !important; }
  &.black { fill: $black !important; }
  &.blue { fill: $blue !important; }
  &.lightBlue { fill: $lightBlue !important; }
  &.royalBlue { fill: $royalBlue !important; }
  &.orange { fill: $orange !important; }
  &.lightOrange { fill: $lightOrange !important; }
  &.novemberOrange { fill: $novemberOrange !important; }
  &.yellow { fill: $yellow !important; }
  &.pink { fill: $pink !important; }
  &.darkPink { fill: $darkPink !important; }
  &.purple { fill: $purple !important; }
  &.darkPurple { fill: $darkPurple !important; }
  &.grey { fill: $grey !important; }
  &.mediumGrey { fill: $mediumGrey !important; }
  &.lightGrey { fill: $lightGrey !important; }
  &.silver { fill: $silver !important; }
  &.cyan { fill: $cyan !important; }
  &.magenta { fill: $magenta !important; }
  &.waste { fill: $waste !important; }
  &.batched { fill: $batched !important; }
  &.white { fill: $white !important; }
  &.gradingSilver { fill: $gradingSilver !important; }
  &.gradingDiamond { fill: $gradingDiamond !important; }
  &.gradingBronze { fill: $gradingBronze !important; }
  &.gradingGold { fill: $gradingGold !important; }
  &.gradingPlatinum { fill: $gradingPlatinum !important; }
}

.colour {
  &.text {
    &.primary { color: $primary; }
    &.secondary { color: $secondary; }
    &.pineGreen { color: $pineGreen; }
    &.azureBlue { color: $azureBlue; }
    &.green { color: $green; }
    &.darkGreen { color: $darkGreen; }
    &.limeGreen { color: $limeGreen; }
    &.red { color: $red; }
    &.black { color: $black; }
    &.blue { color: $blue; }
    &.lightBlue { color: $lightBlue }
    &.royalBlue { color: $royalBlue; }
    &.orange { color: $orange; }
    &.lightOrange { color: $lightOrange; }
    &.novemberOrange { color: $novemberOrange; }
    &.yellow { color: $yellow; }
    &.pink { color: $pink; }
    &.darkPink { color: $darkPink; }
    &.purple { color: $purple; }
    &.darkPurple { color: $darkPurple; }
    &.grey { color: $grey;  }
    &.mediumGrey { color: $mediumGrey; }
    &.lightGrey { color: $lightGrey; }
    &.silver { color: $silver; }
    &.cyan { color: $cyan; }
    &.magenta { color: $magenta; }
    &.waste { color: $waste; }
    &.batched { color: $batched; }
    &.white { color: $white; }
    &.gradingSilver { color: $gradingSilver; }
    &.gradingDiamond { color: $gradingDiamond; }
    &.gradingBronze { color: $gradingBronze; }
    &.gradingGold { color: $gradingGold; }
    &.gradingPlatinum { color: $gradingPlatinum; }
  }
  
  &.background {
    &.primary { background-color: $primary; }
    &.secondary { background-color: $secondary; }
    &.pineGreen { background-color: $pineGreen; }
    &.azureBlue { background-color: $azureBlue; }
    &.green { background-color: $green; }
    &.darkGreen { background-color: $darkGreen; }
    &.limeGreen { background-color: $limeGreen; }
    &.red { background-color: $red; }
    &.black { background-color: $black; }
    &.blue { background-color: $blue; }
    &.lightBlue { background-color: $lightBlue }
    &.royalBlue { background-color: $royalBlue; }
    &.orange { background-color: $orange; }
    &.lightOrange { background-color: $lightOrange; }
    &.novemberOrange { background-color: $novemberOrange; }
    &.yellow { background-color: $yellow; }
    &.pink { background-color: $pink; }
    &.darkPink { background-color: $darkPink; }
    &.purple { background-color: $purple; }
    &.darkPurple { background-color: $darkPurple; }
    &.grey { background-color: $grey;  }
    &.mediumGrey { background-color: $mediumGrey; }
    &.lightGrey { background-color: $lightGrey; }
    &.silver { background-color: $silver; }
    &.cyan { background-color: $cyan; }
    &.magenta { background-color: $magenta; }
    &.waste { background-color: $waste; }
    &.batched { background-color: $batched; }
    &.gradingSilver { background-color: $gradingSilver; }
    &.gradingDiamond { background-color: $gradingDiamond; }
    &.gradingBronze { background-color: $gradingBronze; }
    &.gradingGold { background-color: $gradingGold; }
    &.gradingPlatinum { background-color: $gradingPlatinum; }
  }
  
  &.background-2 {
    &.primary { background-color: rgba($primary, 0.2); } 
    &.secondary { background-color: rgba($secondary, 0.2); }
    &.pineGreen { background-color: rgba($pineGreen, 0.2); }
    &.azureBlue { background-color: rgba($azureBlue, 0.2); }
    &.green { background-color: rgba($green, 0.2); }
    &.darkGreen { background-color: rgba($darkGreen, 0.2); }
    &.limeGreen { background-color: rgba($limeGreen, 0.2); }
    &.red { background-color: rgba($red, 0.2); }
    &.black { background-color: rgba($black, 0.2); }
    &.blue { background-color: rgba($blue, 0.2); }
    &.blue-grey { background-color: rgba($blue-grey, 0.2); }
    &.lightBlue { background-color: rgba($lightBlue, 0.2); }
    &.royalBlue { background-color: rgba($royalBlue, 0.2); }
    &.orange { background-color: rgba($orange, 0.2); }
    &.lightOrange { background-color: rgba($lightOrange, 0.2); }
    &.novemberOrange { background-color: rgba($novemberOrange, 0.2); }
    &.yellow { background-color: rgba($yellow, 0.2); }
    &.pink { background-color: rgba($pink, 0.2); }
    &.darkPink { background-color: rgba($darkPink, 0.2); }
    &.purple { background-color: rgba($purple, 0.2); }
    &.darkPurple { background-color: rgba($darkPurple, 0.2); }
    &.grey { background-color: rgba($grey, 0.2);  }
    &.lightGrey { background-color: rgba($lightGrey, 0.2); }
    &.silver { background-color: rgba($silver, 0.2); }
    &.cyan { background-color: rgba($cyan, 0.2); }
    &.magenta { background-color: rgba($magenta, 0.2); }
    &.waste { background-color: rgba($waste, 0.2); }
    &.batched { background-color: rgba($batched, 0.2); }
    &.gradingSilver { background-color: rgba($gradingSilver, 0.2); }
    &.gradingDiamond { background-color: rgba($gradingDiamond, 0.2); }
    &.gradingBronze { background-color: rgba($gradingBronze, 0.2); }
    &.gradingGold { background-color: rgba($gradingGold, 0.2); }
    &.gradingPlatinum { background-color: rgba($gradingPlatinum, 0.2); }
  }

  &.background-4 {
    &.primary { background-color: rgba($primary, 0.4); } 
    &.secondary { background-color: rgba($secondary, 0.4); }
    &.pineGreen { background-color: rgba($pineGreen, 0.4); }
    &.azureBlue { background-color: rgba($azureBlue, 0.4); }
    &.green { background-color: rgba($green, 0.4); }
    &.darkGreen { background-color: rgba($darkGreen, 0.4); }
    &.limeGreen { background-color: rgba($limeGreen, 0.4); }
    &.red { background-color: rgba($red, 0.4); }
    &.black { background-color: rgba($black, 0.4); }
    &.blue { background-color: rgba($blue, 0.4); }
    &.blue-grey { background-color: rgba($blue-grey, 0.4); }
    &.lightBlue { background-color: rgba($lightBlue, 0.4); }
    &.royalBlue { background-color: rgba($royalBlue, 0.4); }
    &.orange { background-color: rgba($orange, 0.4); }
    &.lightOrange { background-color: rgba($lightOrange, 0.4); }
    &.novemberOrange { background-color: rgba($novemberOrange, 0.4); }
    &.yellow { background-color: rgba($yellow, 0.4); }
    &.pink { background-color: rgba($pink, 0.4); }
    &.darkPink { background-color: rgba($darkPink, 0.4); }
    &.purple { background-color: rgba($purple, 0.4); }
    &.darkPurple { background-color: rgba($darkPurple, 0.4); }
    &.grey { background-color: rgba($grey, 0.4);  }
    &.lightGrey { background-color: rgba($lightGrey, 0.4); }
    &.silver { background-color: rgba($silver, 0.4); }
    &.cyan { background-color: rgba($cyan, 0.4); }
    &.magenta { background-color: rgba($magenta, 0.4); }
    &.waste { background-color: rgba($waste, 0.4); }
    &.batched { background-color: rgba($batched, 0.4); }
    &.gradingSilver { background-color: rgba($gradingSilver, 0.4); }
    &.gradingDiamond { background-color: rgba($gradingDiamond, 0.4); }
    &.gradingBronze { background-color: rgba($gradingBronze, 0.4); }
    &.gradingGold { background-color: rgba($gradingGold, 0.4); }
    &.gradingPlatinum { background-color: rgba($gradingPlatinum, 0.4); }
  }

  &.background-6 {
    &.primary { background-color: rgba($primary, 0.6); } 
    &.secondary { background-color: rgba($secondary, 0.6); }
    &.pineGreen { background-color: rgba($pineGreen, 0.6); }
    &.azureBlue { background-color: rgba($azureBlue, 0.6); }
    &.green { background-color: rgba($green, 0.6); }
    &.darkGreen { background-color: rgba($darkGreen, 0.6); }
    &.limeGreen { background-color: rgba($limeGreen, 0.6); }
    &.red { background-color: rgba($red, 0.6); }
    &.black { background-color: rgba($black, 0.6); }
    &.blue { background-color: rgba($blue, 0.6); }
    &.lightBlue { background-color: rgba($lightBlue, 0.6); }
    &.royalBlue { background-color: rgba($royalBlue, 0.6); }
    &.orange { background-color: rgba($orange, 0.6); }
    &.lightOrange { background-color: rgba($lightOrange, 0.6); }
    &.novemberOrange { background-color: rgba($novemberOrange, 0.6); }
    &.yellow { background-color: rgba($yellow, 0.6); }
    &.pink { background-color: rgba($pink, 0.6); }
    &.darkPink { background-color: rgba($darkPink, 0.6); }
    &.purple { background-color: rgba($purple, 0.6); }
    &.darkPurple { background-color: rgba($darkPurple, 0.6); }
    &.grey { background-color: rgba($grey, 0.6);  }
    &.lightGrey { background-color: rgba($lightGrey, 0.6); }
    &.silver { background-color: rgba($silver, 0.6); }
    &.cyan { background-color: rgba($cyan, 0.6); }
    &.magenta { background-color: rgba($magenta, 0.6); }
    &.waste { background-color: rgba($waste, 0.6); }
    &.batched { background-color: rgba($batched, 0.6); }
    &.gradingSilver { background-color: rgba($gradingSilver, 0.6); }
    &.gradingDiamond { background-color: rgba($gradingDiamond, 0.6); }
    &.gradingBronze { background-color: rgba($gradingBronze, 0.6); }
    &.gradingGold { background-color: rgba($gradingGold, 0.6); }
    &.gradingPlatinum { background-color: rgba($gradingPlatinum, 0.6); }
  }

  &.background-8 {
    &.primary { background-color: rgba($primary, 0.8); } 
    &.secondary { background-color: rgba($secondary, 0.8); }

    &.fordBlue { background-color: rgba($fordBlue, 0.8); }
    &.pineGreen { background-color: rgba($pineGreen, 0.8); }
    &.azureBlue { background-color: rgba($azureBlue, 0.8); }
    &.green { background-color: rgba($green, 0.8); }
    &.darkGreen { background-color: rgba($darkGreen, 0.8); }
    &.limeGreen { background-color: rgba($limeGreen, 0.8); }
    &.red { background-color: rgba($red, 0.8); }
    &.black { background-color: rgba($black, 0.8); }
    &.blue { background-color: rgba($blue, 0.4); }
    &.lightBlue { background-color: rgba($lightBlue, 0.8); }
    &.royalBlue { background-color: rgba($royalBlue, 0.8); }
    &.orange { background-color: rgba($orange, 0.8); }
    &.lightOrange { background-color: rgba($lightOrange, 0.8); }
    &.novemberOrange { background-color: rgba($novemberOrange, 0.8); }
    &.yellow { background-color: rgba($yellow, 0.8); }
    &.pink { background-color: rgba($pink, 0.8); }
    &.darkPink { background-color: rgba($darkPink, 0.8); }
    &.purple { background-color: rgba($purple, 0.8); }
    &.darkPurple { background-color: rgba($darkPurple, 0.8); }
    &.grey { background-color: rgba($grey, 0.8);  }
    &.lightGrey { background-color: rgba($lightGrey, 0.8); }
    &.silver { background-color: rgba($silver, 0.8); }
    &.cyan { background-color: rgba($cyan, 0.8); }
    &.magenta { background-color: rgba($magenta, 0.8); }
    &.waste { background-color: rgba($waste, 0.8); }
    &.batched { background-color: rgba($batched, 0.8); }
    &.gradingSilver { background-color: rgba($gradingSilver, 0.8); }
    &.gradingDiamond { background-color: rgba($gradingDiamond, 0.8); }
    &.gradingBronze { background-color: rgba($gradingBronze, 0.8); }
    &.gradingGold { background-color: rgba($gradingGold, 0.8); }
    &.gradingPlatinum { background-color: rgba($gradingPlatinum, 0.8); }
  }
}


//colours that deviate from spec, ratain for reference 
//$default: rgba(#000033, 0.9);
//$primary:rgba(#000033, 0.9);
//$secondary:rgba(#708090 , 0.9);
//$background:rgba(#EFEFEF, 1);
//$white:rgba(#FFFFFF, 1);
//$green:rgba(#497d83, 1);
//$pineGreen:rgba(#288654, 0.9);
//$red:rgba(#E93D24, 1);
//$blue:rgba(#005AFF, 1);
//$azureBlue:rgba(#006199, 1);
//$orange:rgba(orange, 1);
//$yellow:rgba(#F0E68C, 1);  
//$pink:rgba(#FFB6C1, 1);  
//$lightGrey:rgba(#EFEFEF, 1);
//$purple:rgba(purple, 1);
//$grey:rgba(grey, 1);
//$blueAgiloBg: url('../assetts/backgrounds/blue.png');
//138076