.header
{
    padding: 0px 6px 0px 0px !important;

    .headerLogo
    {
      width: 100%;
      max-width: 120px;
      cursor: pointer;
    }

    &.modeText
    {
      font-size: 16px;
      font-weight: bold;
      color: black;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }


    &.background-development 
    {
      font-size: 12px;
      margin-right: 10px;
      background: rgba($red, 0.6);
    }

    &.background-staging
    {
      font-size: 12px;
      margin-right: 10px;
      background: rgba($orange, 0.8);
    }

    &.background-live
    {
      font-size: 12px;
      margin-right: 10px;
      background: $primary;
    }


    &.accountName-development
    {
      font-size: 12px;
      color: black;
      margin-right: 10px;
    }

    &.accountName-staging
    {
      font-size: 12px;
      color: black;
      margin-right: 10px;
    }

    &.accountName-live
    {
      font-size: 12px;
      color: white;
      margin-right: 10px;
    }

    @media screen and (max-width: 720px) {
      display: none;
      &.showText {
        display: hidden;
      }
    }

    @media screen and (max-width: 430px) {
      visibility:visible;
      &.showModeText {
        visibility: hidden;
      }
    }
}