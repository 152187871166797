.consumablesHub {
  &.batchedOrders {
    min-height: 120px;
  }
  &.weekDayButton {
    border-radius: 16px;
  }
  &.tonerContainer {
    height: 28px;
  }
  &.tonerContainer-item {
    min-width: 40px;
  }
  &.groupContainer-input {
    padding: 1px 2px 1px 2px;
  }
  &.groupContainer-text-upper {
    white-space: pre-line !important;
    padding: 1px 2px 0px 2px;
  }
  &.groupContainer-text-lower {
    white-space: pre-line !important;
    padding: 0px 2px 1px 2px;
  }
  &.siteDevices-notes-textArea {
    resize: none;
  }
  &.tonerRobotNotes-text-content {
    word-break: break-word !important;
    white-space: pre-line;
  }
  &.batched-every-week {
    margin-left: 10px;
    @media screen and (max-width: 908px) {
      &.batched-every-week {
        margin-left: 0px;
      }
    }
  }
  &.batched-select-dropdown {
    max-width: 150px;
  }
  &.courier-contact-options {
    flex-wrap: nowrap;
    @media screen and (max-width: 550px) {
      &.courier-contact-options {
        flex-wrap: wrap;
      }
    }
  }
  &.courier-contact-options-width {
    @media screen and (max-width: 500px) {
      &.courier-contact-options-width {
        width: 100%;
      }
    }
  }
  &.courier-contact-options-pad {
    @media screen and (max-width: 550px) {
      &.courier-contact-options-pad {
        padding-top: 10px;
      }
    }
  }
  &.manual-order-container-text {
    white-space: pre-line !important;
  }
  &.button-align-250 {
    @media screen and (max-width: 240px) {
      &.button-align-250 {
        justify-content: flex-start;
      }
    }
  }
  &.requestConsumables-search {
    flex-direction: row;
    @media screen and (max-width: 700px) {
      &.requestConsumables-search {
        flex-direction: column;
      }
    }
  }
  &.requestConsumables-form-reverse {
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    @media screen and (max-width: 500px) {
      &.requestConsumables-form-reverse {
        flex-direction: column-reverse;
      }
    }
  }
  &.requestConsumables-form {
    width: 50%;
    @media screen and (max-width: 500px) {
      &.requestConsumables-form {
        width: 100%;
      }
    }
  }
  &.requestConsumables-stats {
    width: 50%;
    @media screen and (max-width: 500px) {
      &.requestConsumables-stats {
        width: 100%;
        height: 180px;
      }
    }
  }
}

