
html, body {   
  height: 100%;
  width: 100%;
  padding: 0px;
  margin: 0px;
}

body {   
  display: flex;
  flex-direction: column;
  background-color: $background;
}