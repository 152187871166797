.importSample {
    overflow-x: scroll;
    white-space: nowrap;

    .sample {
        display: inline-grid;
        position: relative;

        h3 {
            text-align: center;
            width: 22px;
        }

        .textInput {
            width: 220px;
        }

        width: 250px;
        margin-right: 10px;
        overflow-x: hidden;

        .clearButton {
            position: absolute;
            bottom: 10px;
            left: 0;
            width: 230px;
            display: flex;
            justify-content: center;
        }
    }
}