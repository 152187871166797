.portalhub { 
  &.pagebreak {
    break-before: always;
  }
  &.table {
    break-after: auto;
    border-collapse : collapse;
  }
  tr,
  th,
  td {
    break-inside: avoid;
    border-bottom: 1px solid $mediumGrey;
    &.full-border {
      border: 1px solid $mediumGrey;
    }
  }
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }
}
.portalHubUtils {
  &.paperOrder {
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    @media screen and (max-width: 855px) {
      &.paperOrder {
        flex-direction: column-reverse;
      }
    }
  }
  &.paperLeft {
    width: 70%;
    @media screen and (max-width: 855px) {
      &.paperLeft {
        width: 100%;
      }
    }
  }
  &.paperRight {
    width: 30%;
    @media screen and (max-width: 855px) {
      &.paperRight {
        width: 100%;
      }
    }
  }
}