.drawer {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 400px;
    background-color: white;
    padding: 20px;
    padding-top: 55px;
    overflow-y: auto;
    z-index: 10;
    overflow: auto;
    @include transition(right, 0.4s ease);

    .drawerClose {
        position: absolute;
        left: 15px;
        top: 15px;
        cursor: pointer;
        font-size: 20px;
    }

    &.closed {
        right: -400px;
    }
}