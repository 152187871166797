
.frontPage {
  &.frontPageModal {

    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    background-size: cover;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

  }

  &.frontPageModalContent {

    display: flex;
    flex-direction: column;

    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    border-radius: 10px;
    width: 40%; 
    min-width: 300px; 
    max-height: 60%; 
    text-align: center;
    padding: 20px;
  }

  &.frontPageOverflowContainer {
    @media screen and (max-height: 880px), (max-width: 900px) {
      &.frontPageOverflowContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: auto;
      }
    }
  }
} 

