 .flex-container {

  display: flex;
  
  &.row {
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &.row-reverse {
    flex: 0 1 auto;
    flex-direction: row-reverse;
    flex-wrap: wrap;
  }

  &.overflow-hidden { overflow: hidden; }
  &.flex-auto { flex: 'auto'; }
  &.flex-0 { flex: 0; }
  &.flex-1 { flex: 1 1 0px; }

  &.column { flex-direction: column; }
  &.column-reverse { flex-direction: column-reverse; }

  &.nowrap { flex-wrap: nowrap; }
  &.wrap { flex-wrap: wrap; }
  &.wrap-reverse { flex-wrap: wrap-reverse; }
  &.column-wrap { flex-flow: column wrap; }

  &.start { justify-content: flex-start; }
  &.end { justify-content: flex-end; }
  &.center { justify-content: center; }
  &.between { justify-content: space-between; }
  &.around { justify-content: space-around; }
  &.evenly { justify-content: space-evenly; }

  &.stretch { align-items: stretch }; 
  &.baseline { align-items: baseline };

  &.padding-1 { padding: 1px }
  &.padding-2 { padding: 2px }
  &.padding-4 { padding: 4px }
  &.padding-6 { padding: 6px }
  &.padding-8 { padding: 8px }

  &.margin-1 { margin: 1px }
  &.margin-2 { margin: 2px }
  &.margin-4 { margin: 4px }
  &.margin-6 { margin: 6px }
  &.margin-8 { margin: 8px }

  &.clickable:hover {
    background-color: lightGrey;
    transition: 0.3s;
  }

  .flex-item {

    display: flex;

    &.flex-auto { flex: 'auto'; }
    &.flex-0 { flex: 0; }
    &.flex-1 { flex: 1 1 0%; }

    &.order-0 { order: 0 }
    &.order-1 { order: 1 }
    &.order-2 { order: 2 }
    &.order-3 { order: 3 }
    &.order-4 { order: 4 }
    &.order-5 { order: 5 }

    &.flex-grow-0 { flex-grow: 0 }
    &.flex-grow-1 { flex-grow: 1 }
    &.flex-grow-2 { flex-grow: 2 }
    &.flex-grow-3 { flex-grow: 3 }
    &.flex-grow-4 { flex-grow: 4 }
    &.flex-grow-5 { flex-grow: 5 }

    &.flex-shrink-0 { flex-shrink: 0 }
    &.flex-shrink-1 { flex-shrink: 1 }
    &.flex-shrink-2 { flex-shrink: 2 }
    &.flex-shrink-3 { flex-shrink: 3 }
    &.flex-shrink-4 { flex-shrink: 4 }
    &.flex-shrink-5 { flex-shrink: 5 }

    &.flex-basis-100 { flex-basis: 100% }
    &.flex-basis-90 { flex-basis: 90% }
    &.flex-basis-80 { flex-basis: 80% }
    &.flex-basis-75 { flex-basis: 75% }
    &.flex-basis-70 { flex-basis: 70% }
    &.flex-basis-60 { flex-basis: 60% }
    &.flex-basis-50 { flex-basis: 50% }
    &.flex-basis-40 { flex-basis: 40% }
    &.flex-basis-30 { flex-basis: 30% }
    &.flex-basis-25 { flex-basis: 25% }
    &.flex-basis-20 { flex-basis: 20% }
    &.flex-basis-10 { flex-basis: 10% }
    &.flex-basis-0 { flex-basis: 0 }
    &.flex-basis-1 { flex-basis: 1 }
    &.flex-basis-2 { flex-basis: 2 }
    &.flex-basis-3 { flex-basis: 3 }
    &.flex-basis-4 { flex-basis: 4 }
    &.flex-basis-5 { flex-basis: 5 }
    &.flex-basis-6 { flex-basis: 6 }
    &.flex-basis-7 { flex-basis: 7 }
    &.flex-basis-8 { flex-basis: 8 }
    &.flex-basis-9 { flex-basis: 9 }

    &.none { flex: none }
    &.grow { flex: flex-grow }
    &.shrink { flex: flex-shrink }
    &.basis { flex: flex-basis }

    &.start { justify-content: flex-start; }
    &.end { justify-content: flex-end; }
    &.center { justify-content: center; }
    &.between { justify-content: space-between; }
    &.around { justify-content: space-around; }
    &.evenly { justify-content: space-evenly; }

    &.nowrap { flex-wrap: nowrap; }
    &.wrap { flex-wrap: wrap; }
    &.wrap-reverse { flex-wrap: wrap-reverse; }
    &.column-wrap { flex-flow: column wrap; }

    &.align-auto  { align-self: auto }
    &.align-start  { align-self: start }
    &.align-end { align-self: end }
    &.align-center { align-self: center }
    &.align-baseline  { align-self: baseline }
    &.align-stretch { align-self: stretch }

    &.padding-2 { padding: 2px }
    &.padding-4 { padding: 4px }
    &.padding-6 { padding: 6px }
    &.padding-8 { padding: 8px }

    &.margin-2 { margin: 2px }
    &.margin-4 { margin: 4px }
    &.margin-6 { margin: 6px }
    &.margin-8 { margin: 8px }

    &.clickable:hover {
      background-color: lightGrey;
      transition: 0.3s;
    }

  }

}